import React from "react";
import BlogPage from "../components/BlogPage";

function Blog() {
  return (
    <div>
      <BlogPage />
    </div>
  );
}

export default Blog;
