import logo from "./logo.svg";
import "./App.css";
import "./style.css";
import "./styles/form.css";
import "./styles/meanmenu.css";
import "./styles/responsive.css";
// import "./styles/remixicon.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error from "./pages/404Error";
import Blog from "./pages/Blog";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import DataAi from "./pages/expertise pages/Data&Ai";
import DevxOps from "./pages/expertise pages/DevxOps";
import TestAutomation from "./pages/expertise pages/TestAutomation";
import BusinessAutomation from "./pages/expertise pages/BusinessAutomation";
import Cybersecurity from "./pages/expertise pages/Cybersecurity";
import ManagedItServices from "./pages/expertise pages/ManagedItServices";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import CookieBanner from "./components/CookieBanner";
import GenerativeAi from "./pages/expertise pages/GenerativeAi";
import IntegrationApplications from "./pages/expertise pages/Integration&Applications";

function App() {
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  useEffect(() => {
    const cookiesAccepted = Cookies.get("cookiesAccepted");
    setShowCookieBanner(!cookiesAccepted);
  }, []);
  return (
    <div className="App">
      {showCookieBanner && <CookieBanner />}
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/blogs" element={<Blog />}></Route>
          <Route exact path="expertise" element={<Services />}></Route>
          <Route exact path="expertise/data-ai" element={<DataAi />}></Route>
          <Route exact path="/expertise/devxops" element={<DevxOps />}></Route>
          <Route
            exact
            path="expertise/test-automation"
            element={<TestAutomation />}
          ></Route>
          <Route
            exact
            path="/expertise/cybersecurity"
            element={<Cybersecurity />}
          ></Route>
          <Route
            exact
            path="expertise/business-automations"
            element={<BusinessAutomation />}
          ></Route>
          <Route
            exact
            path="expertise/managed-it-services"
            element={<ManagedItServices />}
          ></Route>
          <Route
            exact
            path="expertise/generative-ai"
            element={<GenerativeAi />}
          ></Route>
          <Route
            exact
            path="expertise/integrations-applications"
            element={<IntegrationApplications />}
          ></Route>
          <Route exact path="*" element={<Error />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
