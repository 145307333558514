import React, { useState } from "react";
import { Link } from "react-router-dom";

import TabNation from "../../components/TabSection";
import LetUsHelpSection from "../../components/LetUsHelpSection";

function DataAi() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Data & Ai</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>Data & Ai</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {/* <div
                className="page-banner-image"
                // data-speed="0.08"
                // data-revert="true"
              >
                <img src="../assets/images/page-banner-image.png" alt="Tech Sevants" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/data-ai.jpg"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>Data & Ai</h3>
                  <p>
                    From a technical perspective, we look at data end to end. We
                    think about the quality of the data and how it’s received.
                    We believe that data needs to be part of an architecture
                    that is purpose-built. Metadata must be defined, understood,
                    and easy to use. Its lineage must be tracked. It must be
                    secured. And it must be available to the end users and
                    systems that need it.
                  </p>
                  <p>
                    Once in a nirvana state, there must be a sustainable
                    governance process to keep it that way. It should be an
                    automated process that also takes the human factor into
                    account. These are the types of initiatives our team helps
                    our clients to drive.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>It starts with the right data strategy for you</span>
                  <p>Manage, analyze and uncover value with enterprise data.</p>
                  <p>
                    Tech Savants understands that your data is critical to
                    generating revenue, growing your business, and maintaining a
                    competitive advantage.
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-5">Key Elements of Data & AI</h3>
                  <div className="tab-container">
                    <div className="tab-buttons">
                      <button
                        className={`tab-btn ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        Advanced Analytics
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        Data Governance
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab3")}
                      >
                        Data Privacy
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab4" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab4")}
                      >
                        MDM/Data Hubs
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "tab1" && (
                        <div className="services-details-content">
                          <h3>Get the Most Out Of Your Data - Start Now</h3>
                          <p>
                            Go beyond Business Intelligence to uncover
                            actionable insights, inform your next steps and make
                            predictions for the future.
                          </p>
                          <p>
                            With our years of experience in data validation,
                            data modelling, visualizations, and extensive data
                            services, we unlock the hidden potential of your
                            data.
                          </p>
                          <ul className="list p-3">
                            <li>
                              Gives you the competitive advantage to become an
                              industry disruptor
                            </li>
                            <li>
                              Increases productivity of your data science teams.
                            </li>
                            <li>Answers the question “What’s next?”</li>
                            <li>Improves decision-making</li>
                            <li>
                              Uncovers transformative patterns and identifies
                              drivers
                            </li>
                          </ul>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="services-details-content">
                          <h3>Data Governance</h3>
                          <p>
                            Given the higher volume of data and the variety of
                            formats, both structured and unstructured, our
                            methodology recognizes that Data Governance is not
                            just about technology.
                          </p>
                          <p>
                            Enterprise data empowers you to recognize the full
                            and long-term impact of your data as a true and
                            valued asset. Tech Savants helps you achieve this
                            through a comprehensive data governance policy that
                            includes: roles and responsibilities, security,
                            privacy, and compliance, integrity, usability and
                            integration; and internal and external data flow.
                          </p>
                          <ul className="list p-3">
                            <li>
                              <span>Improves the quality of the data</span>
                            </li>
                            <li>
                              <span>
                                Ensures that data is as accurate and complete as
                                possible
                              </span>
                            </li>
                            <li>
                              <span>
                                Makes data more accessible and easier to apply
                              </span>
                            </li>
                            <li>
                              <span>
                                Provides a sense of consistency across all data
                              </span>
                            </li>
                            <li>
                              <span>Enhances decision-making and planning</span>
                            </li>
                            <li>
                              <span>
                                Streamlines operational efficiency and resource
                                allocation
                              </span>
                            </li>
                            <li>
                              <span>
                                Bolsters security and mitigates the risks of
                                data being lost
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                      {activeTab === "tab3" && (
                        <div className="services-details-content">
                          <h3>Data Privacy</h3>
                          <p>
                            Data Privacy means knowing where your data is, who
                            has access to it and where it’s going.
                          </p>
                          <p>
                            Our data privacy specialists have the experience and
                            expertise with privacy policies, processes, and
                            technologies to build, run, assess, and optimize
                            your data privacy management. We deliver custom data
                            privacy solutions no matter your data environment or
                            risk profile.
                          </p>
                          <ul className="list p-3">
                            <li>
                              <span>
                                Increases customer satisfaction and brand
                                loyalty
                              </span>
                            </li>
                            <li>
                              <span>
                                Understands and measures your data risks
                              </span>
                            </li>
                            <li>
                              <span>
                                Accommodates future compliance and privacy
                                regulation
                              </span>
                            </li>
                            <li>
                              <span>
                                Avoids fines, penalties, and potential
                                litigation
                              </span>
                            </li>
                            <li>
                              <span>
                                Protects against data breaches and provides
                                audit support
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                      {activeTab === "tab4" && (
                        <div className="services-details-content">
                          <h3>MDM/Data Hubs</h3>
                          <p>
                            Master Data Management (MDM) and Data Hubs are
                            exchanges that unify your data and send it
                            frictionlessly to where it’s needed.
                          </p>
                          <p>
                            Connecting the locations of production and
                            consumption, data is regulated centrally. A single
                            platform for governance, reference data, data
                            quality, processes, and workflows is created by this
                            “data-centric” methodology. Businesses that acquire
                            our MDM services may rest easy knowing that all of
                            their data is accurate, current, and consistent.
                          </p>
                          <ul className="list p-3">
                            <li>
                              <span>
                                Create one, integrated and unified view of your
                                data – shared by all systems and people
                              </span>
                            </li>
                            <li>
                              <span>
                                Get data in the hands of those who need it –
                                when, where, and how they need it
                              </span>
                            </li>
                            <li>
                              <span>
                                Build secure, real-time API connections to your
                                customers, partners, and suppliers
                              </span>
                            </li>
                            <li>
                              <span>
                                Connect new and innovative solutions and
                                applications quickly and completely
                              </span>
                            </li>
                            <li>
                              <span>
                                Generate repeatable processes to automatically
                                handle new compliance and privacy regulations
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="services-details-content">
                  <h3>Get the Most Out Of Your Data - Start Now</h3>
                  {/* <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    cursus amet sem venenatis faucibus non maecenas ultricies
                    feugiat amet tellus condimentum eu sed sit ut tempus felis
                    sit sagittis morbi sed ultricies feugiat amet tellu indum eu
                    sed sit ut tempus felis sit sagitti morbi sed lorem
                    Ultricies feugiat amet tellus condimentum eu sed sit loremut
                    tempus felis sit sagittis morbi sed ultricies feugiat
                    ultricies feugiat amet tellus condimentum eu sed sit ut
                    tempus felis sit sagittis morbi sed ultricies feugiat amet
                    tellu indum eu sed sit.
                  </p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/data-collection.png"
                  alt=""
                />

                <p>
                  Get data in the hands of those that need it - when, where, and
                  how they need it
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/timely.png"
                  alt=""
                />
                <p>Ensure timely, confident decision-making</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/secure.png"
                  alt=""
                />
                <p>
                  Unlock the competitive insights hidden in massive amounts of
                  available data
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/feedback.png"
                  alt=""
                />
                <p>
                  Increase customer satisfaction as a good steward of PII and
                  other customer information
                </p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection title={"Connect with a Data and AI Expert"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataAi;
