import React from "react";
import { Link } from "react-router-dom";

function OurServices() {
  return (
    <div>
      <div className="services-area pb-100">
        <div className="container">
          <div className="section-title">
            <span>Areas of Expertise</span>
            <h2>Get Benefit And More With Our Excellent Services</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-services-card">
                <div className="icon">
                  <i className="ri-cast-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/data-ai">Data & AI</Link>
                </h3>
                <p>
                  We understand that your data is critical to generating
                  revenue, growing your business, and maintaining a competitive
                  advantage.
                </p>
                <Link to="/expertise/data-ai" className="services-btn">
                  Learn More
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services-card">
                <div className="icon color-two">
                  <i className="ri-codepen-fill"></i>
                </div>
                <h3>
                  <a href="/expertise/integrations-applications">
                    Integration & Applications
                  </a>
                </h3>
                <p>
                  To be a true, agile digital business poised for growth, you
                  need to align your applications design with an integration
                  strategy.
                </p>
                <Link
                  to="/expertise/integrations-applications"
                  className="services-btn"
                >
                  Learn More
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services-card">
                <div className="icon color-three">
                  <i className="ri-stack-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/devxops">DevXOps</Link>
                </h3>
                <p>
                  DevXOps to be a powerful tool for companies facing today’s
                  business challenges – financial uncertainty and data
                  explosion.
                </p>
                <Link to="/expertise/devxops" className="services-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>

          <div className="view-more-btn">
            <Link to="/expertise" className="default-btn">
              View More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
