import React from "react";

function AboutSection() {
  return (
    <div>
      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-lg-6 col-md-12"
              //   data-aos="fade-right"
              //   data-aos-delay="50"
              //   data-aos-duration="500"
              //   data-aos-once="true"
            >
              <div className="about-image">
                <img src="./assets/images/about-1.png" alt="Tech Savants" />
              </div>
            </div>

            <div
              className="col-lg-6 col-md-12"
              //   data-aos="fade-left"
              //   data-aos-delay="50"
              //   data-aos-duration="500"
              //   data-aos-once="true"
            >
              <div className="about-content">
                <span>About Tech Savants</span>
                <h3>Our Story in IT Excellence</h3>
                <p>
                  Tech Savants has 2 decades of experience in assisting
                  organizations throughout the world with their IT & Digital
                  needs and support. We specialize in helping our clients to
                  connect with their customers, build ideas, and sell their
                  services through a variety of services driven with proven ROI.
                  Our philosophy is to connect perfectly with our clients in
                  order to provide effective, efficient, and collaborative
                  solutions for your business's success.
                </p>
                <p>
                  "Our mission is your success." We value our clients’ success
                  ahead of our own. We measure our own success on how well we
                  help them achieve their business goals and objectives using
                  our expert & professional services.
                </p>
                <div className="about-btn">
                  <a href="/" className="default-btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-shape-2">
          <img src="./assets/images/shape-2.png" alt="Tech Savants" />
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
