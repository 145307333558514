import React, { useState } from "react";
import { Link } from "react-router-dom";

import TabNation from "../../components/TabSection";
import LetUsHelpSection from "../../components/LetUsHelpSection";

function GenerativeAi() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Generative AI</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>Generative AI</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {/* <div
                className="page-banner-image"
                // data-speed="0.08"
                // data-revert="true"
              >
                <img src="../assets/images/page-banner-image.png" alt="Tech Sevants" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/expertise-8.jpg"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>Generative AI</h3>
                  <p>
                    Generative artificial intelligence – Generative AI or simply
                    Gen AI – is reinventing how work gets done across all
                    industries. Previously, companies would start with processes
                    already in place and add AI to it. Now, you start with Gen
                    AI and rethink what is possible. It’s a new time of bold and
                    innovative integration of AI into the very core of business.
                  </p>
                  <p>
                    With Gen AI, you can implement AI much quicker, with greatly
                    reduced costs, than ever before. Its potential use cases are
                    infinite – just combine your imagination with your business
                    goals.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>
                    A Revolutionary Technology That Will Change the Nature of
                    Business
                  </span>
                  <p>Manage, analyze and uncover value with enterprise data.</p>
                  <p>
                    Imagine: your customers getting immediate, detailed
                    responses from an ask-me-anything, empathetic chatbot; your
                    factory workers having instant access to schematics,
                    manuals, or anything they need to keep the machines running;
                    your sales team creating original presentations on demand;
                    and your finance team analyzing data in ways they never
                    could before. That’s Gen AI, and that’s only the beginning.
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-5">
                    Key Elements of Gen AI at Tech Savants
                  </h3>
                  <div className="tab-container">
                    <div className="tab-buttons">
                      <button
                        className={`tab-btn ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        Why Tech Savants for your Gen AI?
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        watsonx and Gen AI
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "tab1" && (
                        <div className="services-details-content">
                          <h3>Why Tech Savants for your Gen AI?</h3>
                          <p>
                            watsonx is IBM’s next generation AI platform.
                            Together, watsonx and Tech Savants will help you
                            unlock the potential of Generative AI (as well as
                            traditional AI) across your business, leveraging
                            critical, trusted data wherever it resides. We’ll
                            tailor Generative AI to your enterprise, ensuring
                            that it’s:
                          </p>

                          <ul className="list p-3">
                            <li>
                              Open – Based on the best open technologies
                              available, with access to the innovation of the
                              open community and multiple models.
                            </li>
                            <li>
                              Trusted – Offering security and data protection,
                              with governance, transparency, and ethics that
                              support increasing regulatory compliance demands.
                            </li>
                            <li>
                              Targeted – Designed and targeted for business use
                              cases that unlock new value, with models that can
                              be tuned to your proprietary data.
                            </li>
                            <li>
                              Empowering – A platform to bring your own data and
                              AI models that you tune, train, deploy, and
                              govern; all while running anywhere, designed for
                              scale and widespread adoption.
                            </li>
                          </ul>
                          <p>
                            Let Tech Savants and watsonx guide you into the Gen
                            AI world. Connect with a Tech Savants watsonx expert
                          </p>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="services-details-content">
                          <h3>watsonx and Gen AI</h3>

                          <ul className="list p-3">
                            <li>
                              <span>
                                We’ve made considerable investments in Gen AI
                                use cases at the virtual Tech Savants Innovation
                                Center, so we are well positioned to help you
                                understand what you would need to first invest
                                in and implement.
                              </span>
                            </li>
                            <li>
                              <span>
                                You’ll have the full benefit of the Prolifics’
                                “Power of 1.” We’ll generate impactful ideas in
                                one day; we’ll give you proof of value in your
                                context in one week; we’ll deliver an MVP with
                                tangible business value in one month; and scale
                                to deliver 10x ROI in one year.
                              </span>
                            </li>
                            <li>
                              <span>
                                We’ll help you understand what the best
                                technology is for your Gen AI use cases –
                                because we’ve already done it with a range of
                                different technologies. We’re not tied into one
                                specific technology solution.
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="services-details-content">
                  <h3>
                    Experience the Tech Savants Art of the Possible with
                    Generative AI
                  </h3>
                  {/* <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    cursus amet sem venenatis faucibus non maecenas ultricies
                    feugiat amet tellus condimentum eu sed sit ut tempus felis
                    sit sagittis morbi sed ultricies feugiat amet tellu indum eu
                    sed sit ut tempus felis sit sagitti morbi sed lorem
                    Ultricies feugiat amet tellus condimentum eu sed sit loremut
                    tempus felis sit sagittis morbi sed ultricies feugiat
                    ultricies feugiat amet tellus condimentum eu sed sit ut
                    tempus felis sit sagittis morbi sed ultricies feugiat amet
                    tellu indum eu sed sit.
                  </p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/creation.png"
                  alt=""
                />

                <p>On-demand creation of original content</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/brain.png"
                  alt=""
                />
                <p>Instant access to knowledge</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/analysis.png"
                  alt=""
                />
                <p>Limitless data analytics</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/prompt.png"
                  alt=""
                />
                <p>Programming with prompts, not code</p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection title={"Talk to a Gen AI expert"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerativeAi;
