import React from "react";
import { Link } from "react-router-dom";

function BannerArea() {
  return (
    <div>
      <div className="main-banner-area">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-lg-5 col-md-12"
              //   data-aos="fade-right"
              //   data-aos-delay="50"
              //   data-aos-duration="500"
              //   data-aos-once="true"
            >
              <div
                className="main-banner-content"
                data-speed="0.05"
                data-revert="true"
              >
                <span>Hi, we are Tech Savants</span>
                <h1>Transforming Ideas into Digital Realities.</h1>
                <p>
                  Elevate your business with cutting-edge IT solutions that pave
                  the way for innovation and ensure your success in the digital
                  realm. Our tailored strategies and robust technologies are
                  designed to transform your challenges into opportunities.
                </p>
                <div className="banner-btn">
                  <Link to="/contact" className="default-btn">
                    Get A Quote
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="col-lg-7 col-md-12"
              //   data-aos="fade-left"
              //   data-aos-delay="50"
              //   data-aos-duration="500"
              //   data-aos-once="true"
            >
              <div className="main-banner-image-slides ">
                <div className="slides-image-item">
                  <img src="./assets/images/banner-2.jpg" alt="Tech Savants" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerArea;
