import React, { useState } from "react";
import { Link } from "react-router-dom";

import TabNation from "../../components/TabSection";
import LetUsHelpSection from "../../components/LetUsHelpSection";

function BusinessAutomation() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Business Automation</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>Business Automation</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {/* <div
                className="page-banner-image"
                // data-speed="0.08"
                // data-revert="true"
              >
                <img src="../assets/images/page-banner-image.png" alt="Tech Sevants" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/expertise-6.webp"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>Automate Business Processes with Powered Intelligence</h3>
                  <p>
                    Implementing intelligent business automation solutions
                    across your organizational workflows empowers a company to
                    adapt and tackle real-world challenges and opportunities on
                    a broad scale. However, achieving and sustaining operational
                    excellence can be challenging – and you recognize when your
                    operational performance isn't up to par: Your key
                    performance indicators (KPIs) are not meeting expectations;
                    you're compensating knowledge workers for routine and
                    mundane tasks; your processes are depleting financial
                    resources and time, and customer satisfaction is on the
                    decline. What's the remedy? Tech Savants' business
                    automation services.
                  </p>
                  <p>
                    Optimize your operations and embrace seamless business
                    continuity with business automation services.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>Achieve Operational Excellence</span>

                  <p>
                    Pinpoint inefficiencies and automate where it has the most
                    impact, so your teams can concentrate on higher value
                    operations.
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-5">Key Elements Of Business Automation</h3>
                  <div className="tab-container">
                    <div className="tab-buttons">
                      <button
                        className={`tab-btn ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        Process Mining
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        AI-Powered Automation
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab3")}
                      >
                        Digital Workers
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "tab1" && (
                        <div className="services-details-content">
                          <h3>Process Mining</h3>
                          <p className="p-3">
                            As digital transformation initiatives are being
                            accelerated by more and more enterprises, the need
                            for effective solutions to analyze and monitor
                            business processes has increased.
                          </p>
                          <p className="px-3">
                            With the aid of process mining, thoroughly examining
                            and tracking corporate processes becomes much more
                            efficient and offers powerful insights into the
                            efficiency of business operations. Using your data,
                            we help you discover a clear visualization of how
                            your business can seamlessly operate.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="services-details-content">
                          <h3>AI-Powered Automation</h3>
                          <p className="p-3">
                            These are difficult times. More than ever, companies
                            seek ways to optimize and streamline their
                            processes. Additionally, an increasing number of
                            businesses are seeing the possibilities of fusing
                            robotic process automation (RPA) and artificial
                            intelligence (AI) in their search for increased
                            efficiency and improved consumer experiences (RPA)
                          </p>
                          <p className="px-3">
                            One of the most effective tools for businesses of
                            the future is AI-powered Process Automation, which
                            may help them tackle the difficulties of our
                            increasingly complex and digital environment.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab3" && (
                        <div className="services-details-content">
                          <h3>Digital Workers</h3>
                          <p>
                            Digital workers are familiar with social
                            interaction. They collaborate with controllers and
                            are set up to reply to queries and take action on
                            behalf of humans, giving them control, authority,
                            and increased productivity. They combine AI, machine
                            learning, RPA, and analytics to completely automate
                            corporate activities, enhancing and augmenting human
                            work.
                          </p>
                          <p>
                            You can rely on a bot to perform work for you. Put
                            your trust in a digital worker to improve any job
                            role.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="services-details-content">
                  <h3>
                    Achieve Operational Excellence With Business Automation
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/efficiency.png"
                  alt=""
                />

                <p>
                  Improve process efficiency and reduce costs through process
                  mining and process automation
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/precise.png"
                  alt=""
                />
                <p>
                  Generate precise, consistent and agile decision making by
                  automating rules
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/ml.png"
                  alt=""
                />
                <p>
                  Leverage AI/ML for operational intelligence and further
                  optimization
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols  text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/patterens.png"
                  alt=""
                />
                <p>
                  Detect sophisticated data patterns to predict potential
                  outcomes
                </p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection
                title={"Connect with a Business Automation Expert"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessAutomation;
