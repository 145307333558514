import React from "react";
import "../styles/footer.css";
import { a } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="footer-area pt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <div className="widget-logo">
                  <a href="/">
                    <img
                      src="./assets/images/logo-3.svg"
                      style={{ height: "80px" }}
                      alt="Tech Savants"
                    />
                  </a>
                </div>
                <p>
                  A Digital Innovation Workplace, where technical solutions are
                  skillfully crafted and achieved. We deliver solutions that are
                  created with Integrity, Passion, and Excellence.
                </p>
                <ul className="widget-social">
                  <li>
                    <a href="https://www.facebook.com" target="_blank">
                      <i className="ri-facebook-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/?lang=en" target="_blank">
                      <i className="ri-twitter-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="ri-instagram-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/signup" target="_blank">
                      <i className="ri-linkedin-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget ps-5">
                <h3>Useful Links</h3>

                <ul className="quick-links">
                  <li>
                    <div className="row justify-content-center">
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/about"}>About Us</a>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/faq"}>FAQ</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row justify-content-center">
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/team"}>Team</a>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/case-study"}>Case Study</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row justify-content-center">
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/expertise"}>Services</a>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/privacy-policy"}>Privacy Policy</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row justify-content-center">
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/"}>Categories</a>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/"}>Search Page</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row ">
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/blogs"}>Blog</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="row ">
                      <div className="col-lg-6 col-sm-6 col-6">
                        <a href={"/support"}>Support</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget ps-5">
                <h3>Our Expertise</h3>

                <ul className="quick-links">
                  <li>
                    <a href={"/expertise/data-ai"}>Data & AI</a>
                  </li>
                  <li>
                    <a href={"/expertise/integrations-applications"}>
                      Integration & Applications
                    </a>
                  </li>
                  <li>
                    <a href={"/expertise/devxops"}>DevXOps</a>
                  </li>
                  <li>
                    <a href={"/expertise/test-automation"}>Test Automation</a>
                  </li>
                  <li>
                    <a href={"/expertise/cybersecurity"}>Cybersecurity</a>
                  </li>
                  <li>
                    <a href={"/expertise/business-automation"}>
                      Business Automation
                    </a>
                  </li>
                  <li>
                    <a href={"/expertise/managed-it-services"}>
                      Managed IT Services
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget ps-3">
                <h3>Contact Information</h3>

                <ul className="footer-information">
                  <li>
                    <i className="ri-map-pin-line"></i>
                    42703 Burbank Ter Sterling VA 20166
                  </li>
                  <li>
                    <i className="ri-phone-fill"></i>
                    <a href="tel:1">571-580-8852</a>
                  </li>
                  <li>
                    <i className="ri-mail-line"></i>
                    <a href="mailto:info@savantsgroupusa.com">
                      <span
                        className="__cf_email__"
                        // data-cfemail="81e8efe7eec1eeede4e4f7afe2eeec"
                      >
                        info@savantsgroupusa.com
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="copyright-area-content">
              <p>Copyright @ 2024 Tech Savants</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
