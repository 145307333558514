import React, { useState } from "react";
import { Link } from "react-router-dom";

import TabNation from "../../components/TabSection";
import LetUsHelpSection from "../../components/LetUsHelpSection";

function IntegrationApplications() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Integrations & Applications</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>Integrations & Applications</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {/* <div
                className="page-banner-image"
                // data-speed="0.08"
                // data-revert="true"
              >
                <img src="../assets/images/page-banner-image.png" alt="Tech Savants" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/expertise-9.jpeg"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>Integrations & Applications</h3>
                  <p>
                    Many of yesterday’s point-to-point integrations aren’t
                    effective in today’s advanced digital environments. Now,
                    it’s about connecting multiple sources – making sure your
                    older systems and newer systems can talk – modernizing where
                    and when it makes sense –moving data from entry point to end
                    user while protecting it and maintaining its integrity.
                  </p>
                  <p>
                    You want an integration solutions that works for you quickly
                    and will evolve with your future needs – take advantage of
                    our deep expertise, customized workshops, exclusive
                    accelerators and repeatable models to deliver your business
                    success.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>Rapid, Scalable Integration</span>
                  <p>
                    To be a true, agile digital business poised for growth, you
                    need to align your applications design with an integration
                    strategy. A successful hybrid integration strategy combines
                    application programming interfaces (APIs)
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-5">
                    Key Elements of Integration & Applications
                  </h3>
                  <div className="tab-container">
                    <div className="tab-buttons">
                      <button
                        className={`tab-btn ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        End-To-End System & API Integration Services
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        Modernized & Connected Systems
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "tab1" && (
                        <div className="services-details-content">
                          <h3>End-To-End System & API Integration Services</h3>
                          <p className="p-3">
                            Our End-To-End System & API Integration Services
                            help you increase speed to market, achieve
                            operational efficiencies, and create a seamless
                            customer experience by tying together your critical
                            applications and data. We have a proven track record
                            of success with API integration projects of all
                            sizes, across industries. Our team helps you design
                            and implement an API integration strategy that meets
                            your unique business needs.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="services-details-content">
                          <h3>Modernized & Connected Systems</h3>
                          <p>
                            As the world continues to digitalize, businesses
                            must adapt their strategies to stay competitive.
                            Tech Savants helps clients by modernizing and
                            connecting their systems using data, application,
                            and API integration techniques. This allows
                            businesses to quickly and easily access the data
                            they need when they need it. In addition, Tech
                            Savants hybrid approach helps businesses keep their
                            existing systems while still being able to take
                            advantage of new technologies. As a result, Tech
                            Savants clients are able to respond quickly to
                            changes in the market and continue to grow and
                            thrive.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="services-details-content">
                  <h3>Get the Most Out Of Your Data - Start Now</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/right.png"
                  alt=""
                />

                <p>
                  Get the right data to the right people at the right time – all
                  in a readily accessible, integrated manner
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/real-time.png"
                  alt=""
                />
                <p>
                  Access a consolidated, real-time view of data that are
                  integrated across devices, platforms, applications
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/faster.png"
                  alt=""
                />
                <p>
                  Realize a faster time to market through design extensibility
                  and reusability
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/ml.png"
                  alt=""
                />
                <p>
                  Leverage the power of artificial intelligence (AI), whether in
                  each application
                </p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection
                title={"Connect with an Integration and Applications Expert"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntegrationApplications;
