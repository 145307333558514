import React, { useState } from "react";
import { Link } from "react-router-dom";

import TabNation from "../../components/TabSection";
import LetUsHelpSection from "../../components/LetUsHelpSection";

function DevxOps() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>DevXOps</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>DevXOps</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {/* <div
                className="page-banner-image"
                // data-speed="0.08"
                // data-revert="true"
              >
                <img src="../assets/images/page-banner-image.png" alt="Tech Sevants" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/expertise-3.jpg"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>DevXOps</h3>
                  <p>
                    Tech Savants considers DevXOps to be a powerful tool for
                    companies facing today’s business challenges – financial
                    uncertainty, talent gaps, shifting rules and regulations,
                    and data explosion. We know it’s a pivotal, horizontal
                    approach that can automate every part of your digital
                    transformation journey.
                  </p>
                  <p>
                    Our DevXOps practices will get you from point A to point B
                    rapidly and reliably, without sacrificing security and
                    quality. You’ll improve efficiency and security, and become
                    an agile organization with the ability to rapidly change or
                    create new business models.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>Your DevXOps Partner</span>

                  <p className="p-3">
                    We refer to it as DevXOps because it can include DataOps,
                    MLOps, CI/CD, Infrastructure as Code for speed; DevSecOps
                    and QA for quality; and AIOps/Continuous Monitoring for
                    availability.
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-5">Key Elements of DevXOps</h3>
                  <div className="tab-container">
                    <div className="tab-buttons">
                      <button
                        className={`tab-btn ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        DataOps
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        AIOps
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab3")}
                      >
                        DevSecOps
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab4" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab4")}
                      >
                        MlOps
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "tab1" && (
                        <div className="services-details-content">
                          <h3>DataOps </h3>
                          <p className="p-3">
                            DataOps is a collaborative data management technique
                            with the goal of enhancing data flows between data
                            managers and data consumers throughout an
                            organization through improved communication,
                            integration, and automation. By establishing
                            predictable delivery and change management of data,
                            data models, and related artifacts, DataOps aims to
                            deliver value more quickly. DataOps makes use of
                            technology to automate the design, implementation,
                            and administration of data delivery with the proper
                            levels of governance. It also makes use of metadata
                            to increase the value and usefulness of data in a
                            dynamic context.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="services-details-content">
                          <h3>AIOps</h3>
                          <p className="p-3">
                            Artificial intelligence (AI) and associated
                            technologies, such as machine learning and natural
                            language processing (NLP), are used for customary IT
                            operations tasks and activities. AIOps supports IT
                            Ops, DevOps, and SRE teams in working smarter and
                            faster so they can identify digital-service issues
                            earlier and address them quickly, preventing
                            disruptions to business operations and customers.
                            This is accomplished through algorithmic analysis of
                            IT data and Observability telemetry.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab3" && (
                        <div className="services-details-content">
                          <h3>DevSecOps</h3>
                          <p className="p-3">
                            Development, security, and operations, also known as
                            DevSecOps, automate the integration of security at
                            each stage of the software development lifecycle,
                            from basic design through integration, testing,
                            deployment, and software delivery.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab4" && (
                        <div className="services-details-content">
                          <h3>MLOps</h3>
                          <p>
                            For the development and improvement of machine
                            learning and AI solutions, MLOps is a helpful
                            methodology. By utilizing continuous integration and
                            deployment (CI/CD) procedures with appropriate
                            monitoring, validation, and governance of ML models,
                            Prolifics’ data scientists and machine learning
                            engineers work together and accelerate the speed of
                            model creation and production by using an MLOps
                            approach.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="services-details-content">
                  <h3>Get Ahead Of The Competition With DevXOps</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/agile.png"
                  alt=""
                />

                <p>
                  Make you an agile organization, with the ability to rapidly
                  change or create new business models
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/publicity.png"
                  alt=""
                />
                <p>
                  Bring products and services to market quicker and more
                  efficiently
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/ai.png"
                  alt=""
                />
                <p>
                  Use AI and automation to ensure adherence to compliance and
                  governance mandates
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/cost.png"
                  alt=""
                />
                <p>
                  Optimize costs through automation – removing the potential for
                  human error from processes
                </p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection title={"Talk to a DevXOps Expert"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevxOps;
