import React from "react";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Contact Us</h2>

                {/* <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Contact</li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-image"
                data-speed="0.08"
                data-revert="true"
              >
                <img
                  src="./assets/images/contact.png"
                  alt="Tech Savants"
                  style={{ height: "180px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-information-area pt-100 pb-75">
        <div className="container">
          <div className="section-title">
            <span>Contact Information</span>
            <h2>Alone We Can Do So Little Together We Can Do So Much</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="contact-information-card">
                <div className="icon">
                  <i className="ri-map-pin-line"></i>
                </div>
                <h3>Address</h3>
                <p>42703 Burbank Ter Sterling VA 20166</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="contact-information-card">
                <div className="icon">
                  <i className="ri-mail-line"></i>
                </div>
                <h3>Email Address</h3>
                <p>
                  <a href="/cdn-cgi/l/email-protection#c4adaaa2ab84aba8a1a1b2eaa7aba9">
                    <span
                      className="__cf_email__"
                      // data-cfemail="2e474048416e41424b4b58004d4143"
                    >
                      info@savantsgroupusa.com
                    </span>
                  </a>{" "}
                  <br />{" "}
                  {/* <a href="/cdn-cgi/l/email-protection#5f3c30312b3e3c2b1f30333a3a29713c3032">
                    <span
                      className="__cf_email__"
                      data-cfemail="d6b5b9b8a2b7b5a296b9bab3b3a0f8b5b9bb"
                    >
                      [email]
                    </span>
                  </a> */}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="contact-information-card">
                <div className="icon">
                  <i className="ri-phone-line"></i>
                </div>
                <h3>Phone Number</h3>
                <p>
                  <a href="tel:91298765436543">+1 571-580-8852</a> <br />{" "}
                  {/* <a href="tel:91298765436766">+91 1111 5436 766</a> */}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="contact-information-card">
                <div className="icon">
                  <i className="ri-time-line"></i>
                </div>
                <h3>Working Hours</h3>
                <p>
                  Open Sunday To Thursday <br /> From 10.00 AM to 07.00 PM
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-area pb-100">
        <div className="container">
          <div className="section-title">
            <h2>Get In Touch</h2>
          </div>

          <form id="contactForm">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <select className="form-select">
                    <option selected>Choose Your Requirements</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Your Name *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Eg: Thomas Adison"
                    required
                    data-error="Please enter your name"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Email *</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="example@Tech Savants.com"
                    required
                    data-error="Please enter your email"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Phone *</label>
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    placeholder="Enter your phone number"
                    required
                    data-error="Please enter your number"
                    className="form-control"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Subject *</label>
                  <input
                    type="text"
                    name="msg_subject"
                    id="msg_subject"
                    placeholder="Enter your subject"
                    className="form-control"
                    required
                    data-error="Please enter your subject"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Your Message</label>
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    placeholder="Type your message"
                    cols="30"
                    rows="6"
                    required
                    data-error="Write your message"
                  ></textarea>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <p className="form-cookies-consent">
                  <input type="checkbox" id="test1" />
                  <label for="test1">
                    Accept{" "}
                    <Link href="/terms-of-service">Terms Of Services</Link> And{" "}
                    <Link href="/privacy-policy">Privacy Policy.</Link>
                  </label>
                </p>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="send-btn">
                  <button type="submit" className="default-btn">
                    Contact Now
                  </button>
                </div>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
