import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <div>
      {" "}
      <div className="error-area ptb-100">
        <div className="container">
          <div className="error-content">
            <img src="./assets/images/error.png" alt="Tech Savants" />
            <h3>Error 404 : Page Not Found</h3>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <Link to={"/"} className="default-btn">
              Back to Homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
