import React from "react";
import { Link } from "react-router-dom";

function LetUsHelpSection({ title }) {
  const defaultHeading = "Start Now - Let Us Help";
  return (
    <div>
      {" "}
      <div className="contact-area ptb-100" id="contact-area">
        <div
          className="container py-5"
          style={{ backgroundColor: "#F4F1EF", borderRadius: "30px" }}
        >
          <div className="section-title">
            <img
              style={{ margin: "6px", width: "100px" }}
              src="../assets/images/help-desk.png"
              alt=""
            />
            <h2>{title || defaultHeading}</h2>
            <p className="mt-2">Speak with our Digital Technology Experts.</p>
          </div>

          <form id="helpForm">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>First Name *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required
                    data-error="Please enter your name"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required
                    data-error="Please enter your name"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Company Name *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required
                    data-error="Please enter your name"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Job Title *</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required
                    data-error="Please enter your name"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Phone *</label>
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    placeholder="Enter your phone number"
                    required
                    data-error="Please enter your number"
                    className="form-control"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Email *</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="example@Tech Savants.com"
                    required
                    data-error="Please enter your email"
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <p className="my-3">
                Tech Savants may use my contact data to keep me informed of
                products, services, and offerings. I can withdraw my marketing
                consent at any time by submitting an opt-out request. I may
                unsubscribe to marketing emails by clicking the unsubscribe link
                in each email. You can access Tech Savants Privacy Statement
                here.
              </p>
              <div className="col-lg-12 col-md-12">
                <p className="form-cookies-consent">
                  <input type="checkbox" id="test1" />
                  <label for="test1">
                    Accept <Link to="/terms-of-service">Terms Of Services</Link>{" "}
                    And <Link to="/privacy-policy">Privacy Policy.</Link>
                  </label>
                </p>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="send-btn">
                  <button type="submit" className="default-btn">
                    Talk to An Expert
                  </button>
                </div>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LetUsHelpSection;
