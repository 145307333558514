import React, { useState } from "react";

function FaqSection() {
  const faqData = [
    {
      id: 1,
      question: "How do creative ideas benefit my business?",
      answer:
        "Creative ideas spark innovation, attract customers' attention, differentiate your brand, and often lead to unique solutions that can propel business growth. We specialize in generating inventive strategies tailored to your specific needs.",
    },
    {
      id: 2,
      question: "What types of brand solutions do you offer",
      answer:
        "We provide a range of brand solutions, including brand identity design, content creation, marketing strategies, digital presence enhancement, and tailored consultancy to strengthen your brand's impact and resonance within your target market.",
    },
    {
      id: 3,
      question:
        "Are your brand solutions adaptable to evolving market trends and changes in consumer behavior",
      answer:
        "Absolutely. We understand that the business landscape is dynamic, and consumer behaviors continually evolve. Our strategies and brand solutions are designed to be flexible and adaptable, allowing us to pivot and adjust strategies in response to emerging trends and changing market dynamics.",
    },
  ];

  const [openAccordionId, setOpenAccordionId] = useState(null);

  const handleAccordionClick = (id) => {
    setOpenAccordionId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div>
      <div className="faq-area ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="faq-content">
                <div className="faq-content">
                  <span>Ask Any Questions</span>
                  <h3>
                    Revitalize Your Business: Elevate Success with Innovative
                    Ideas and Brand Solutions
                  </h3>
                  <p>
                    Discover the Power of Transformative Strategies and
                    Customized Brand Solutions for Amplifying Your Business
                    Presence. At Tech Savants, we're dedicated to fostering
                    innovation, implementing creative ideas, and devising
                    tailored brand solutions that are meticulously crafted to
                    elevate your business to new heights.
                  </p>

                  {/* <div className="row justify-content-center">
                  <div className="col-lg-6 col-sm-6">
                    <div className="faq-circlechart-box">
                      <div className="circlechart" data-percentage="90"></div>
                      <span>Clients Satisfied</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="faq-circlechart-box">
                      <div className="circlechart" data-percentage="75"></div>
                      <span>Projects Completed</span>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="faq-accordion">
                <div className="accordion" id="FaqAccordion">
                  {faqData.map((item) => (
                    <div className="accordion-item" key={item.id}>
                      <button
                        className={`accordion-button ${
                          openAccordionId === item.id ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => handleAccordionClick(item.id)}
                        aria-expanded={
                          openAccordionId === item.id ? "true" : "false"
                        }
                        aria-controls={`collapse${item.id}`}
                      >
                        {item.question}
                      </button>
                      <div
                        id={`collapse${item.id}`}
                        className={`accordion-collapse collapse ${
                          openAccordionId === item.id ? "show" : ""
                        }`}
                        data-bs-parent="#FaqAccordion"
                      >
                        <div className="accordion-body">
                          <p>{item.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqSection;
