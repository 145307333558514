// CookieBanner.js
import React, { useState } from "react";
import Cookies from "js-cookie";
import "../styles/cookieBanner.css";

const CookieBanner = () => {
  const [isVisable, setisVisable] = useState(true);
  const handleAccept = () => {
    Cookies.set("cookiesAccepted", "true", { expires: 365 });
    Cookies.set("visitedPages", JSON.stringify(["home", "about"]));
    setisVisable(!isVisable);
  };

  const handleReject = () => {
    setisVisable(!isVisable);
  };

  return (
    <>
      {isVisable && (
        <div className="cookie-banner">
          <section id="container">
            <section class="sec__container">
              <div class="cookie__box">
                <img
                  src="https://i.postimg.cc/mDLqkpv7/cookie.png"
                  alt="cookie"
                />
                <div class="content">
                  <h2>Cookie Notice</h2>
                  <p>
                    Welcome to Tech Savants! To enhance your browsing experience
                    and provide personalized services, we use cookies.
                  </p>
                  <div class="btn__group">
                    <button class="accept__btn" onClick={handleAccept}>
                      Accept
                    </button>
                    <button onClick={handleReject} class="reject-btn">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
