import React from "react";
import { Link } from "react-router-dom";

function Services() {
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Our Expertise</h2>

                {/* <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Services</li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-image"
                data-speed="0.08"
                data-revert="true"
              >
                <img
                  src="./assets/images/banner-3.svg"
                  alt="Tech Savants"
                  style={{ height: "200px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-area pt-100 pb-100">
        <div className="container">
          <div className="section-title">
            <span>Our Services</span>
            <h2>Get Benefit And More With Our Excellent Services</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-cast-line"></i>
                </div>
                <h3>
                  <Link to="data-ai">Data & AI</Link>
                </h3>
                <p>
                  Tech Savants understand that your data is critical to
                  generating revenue, growing your business, and maintaining a
                  competitive advantage.
                </p>
                <Link to="/expertise/data-ai" className="services-btn">
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-cast-line"></i>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-codepen-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/integrations-applications">
                    Integration & Applications
                  </Link>
                </h3>
                <p>
                  To excel as a digital business poised for growth, align your
                  application design with a hybrid integration strategy. This
                  combines APIs for flexible enterprise applications.
                </p>
                <Link
                  to="/expertise/integrations-applications"
                  className="services-btn"
                >
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-codepen-line"></i>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-stack-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/devxops">DevXOps</Link>
                </h3>
                <p>
                  We refer to it as DevXOps because it can include DataOps,
                  MLOps, CI/CD, Infrastructure as Code for speed; DevSecOps and
                  QA for quality
                </p>
                <Link to="/expertise/devxops" className="services-btn">
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-stack-line"></i>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-compass-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/test-automation">Test Automation</Link>
                </h3>
                <p>
                  Tech Savants knows that testing done correctly and efficiently
                  will improve your customer experience and help you reach your
                  business goals.
                </p>
                <Link to="/expertise/test-automation" className="services-btn">
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-compass-line"></i>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-code-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/cybersecurity">Cybersecurity</Link>
                </h3>
                <p>
                  Tech Savants acts as your trusted go-to partner bringing
                  advanced expertise and knowledge to deal with the current
                  threat landscape.
                </p>
                <Link to="/expertise/cybersecurity" className="services-btn">
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-code-line"></i>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-computer-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/business-automations">
                    Business Automation
                  </Link>
                </h3>
                <p>
                  Pinpoint inefficiencies and automate where it has the most
                  impact, so your teams can focus on higher value operations.
                  Streamlining processes enhances productivity.
                </p>
                <Link
                  to="/expertise/business-automations"
                  className="services-btn"
                >
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-computer-line"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-computer-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/managed-it-services">
                    Managed IT Services
                  </Link>
                </h3>
                <p>
                  Maintaining and innovating mission-critical programs 24/7, 365
                  days a year is crucial. Boost your internal resources by
                  connecting with our technical experts.
                </p>
                <Link
                  to="/expertise/managed-it-services"
                  className="services-btn"
                >
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-computer-line"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <div className="icon">
                  <i className="ri-computer-line"></i>
                </div>
                <h3>
                  <Link to="/expertise/generative-ai">Generative AI</Link>
                </h3>
                <p>
                  Gen AI – is reinventing how work gets done across all
                  industries. Previously, companies would start with processes
                  already in place and add AI to it.
                </p>
                <Link to="/expertise/generative-ai" className="services-btn">
                  Learn More
                </Link>
                <div className="back-icon">
                  <i className="ri-computer-line"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
