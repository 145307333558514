import React, { useState } from "react";
import { Link } from "react-router-dom";

import TabNation from "../../components/TabSection";
import LetUsHelpSection from "../../components/LetUsHelpSection";

function Cybersecurity() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Cybersecurity</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>Cybersecurity</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {/* <div
                className="page-banner-image"
                // data-speed="0.08"
                // data-revert="true"
              >
                <img src="../assets/images/page-banner-image.png" alt="Tech Sevants" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/expertise-5.jpg"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>Improve Your Security Posture</h3>
                  <p>
                    As technology advances and evolves, so does the threat
                    landscape. Yet many organizations implement their technology
                    without proper guidance or direction from cybersecurity
                    specialists.
                  </p>
                  <p>
                    Tech Savants acts as your trusted go-to partner bringing
                    advanced expertise and knowledge to deal with the current
                    threat landscape. Our Cybersecurity Services shield your
                    enterprise against threats and strengthen your cyber
                    defenses.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>Leverage Next-Gen Cybersecurity Services</span>

                  <p>
                    Enable your organization to foster cyber resilience in every
                    organizational process.
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-5">Key Elements Of Cybersecurity</h3>
                  <div className="tab-container">
                    <div className="tab-buttons">
                      <button
                        className={`tab-btn ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        Cybersecurity Incident Management
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        Vulnerability Assessment
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab3")}
                      >
                        Data Breach
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab4" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab4")}
                      >
                        Data Privacy
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "tab1" && (
                        <div className="services-details-content">
                          <h3>Cybersecurity Incident Management</h3>
                          <p className="p-3">
                            A cyber attack on your company can cause a lot of
                            damage to your data, reputation, and bottom line.
                            And despite your best efforts, it might be
                            impossible to completely prevent it from occurring.
                          </p>
                          <p className="px-3">
                            Minimize the Damage. The speed and effectiveness of
                            your response plan can make all the difference in
                            how much damage the cyberattack can cause. If you’re
                            scrambling to identify the issue and plan the next
                            steps, the damage that could be done might be too
                            extensive for your company to recover from.
                            Meanwhile, if you have a cybersecurity incident
                            management process, you can combat the issue head-on
                            and reduce the damage or even stop the attack from
                            having its full impact.
                          </p>
                          <p className="p-3">
                            Maintain Trust. A cyber attack can result in a
                            significant blow to your company’s reputation. And
                            the only way to reduce the damage is to respond
                            quickly and effectively, showing that you are in
                            control and know how to deal with the situation in a
                            way that will protect your customers.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="services-details-content">
                          <h3>Vulnerability Assessment</h3>
                          <p className="p-3">
                            A vulnerability assessment is the process of
                            identifying the biggest bugs and other
                            vulnerabilities in software, systems, and work
                            processes in your company. Specifically, its primary
                            purpose is to look at any weak areas that hackers
                            might exploit so that they can be identified,
                            analyzed, and addressed before they can cause
                            damage.
                          </p>
                          <p className="px-3">
                            The assessment also looks into the severity of the
                            security weaknesses and whether they are likely to
                            be exploited. It can also highlight how these weak
                            areas could cause damage if used by hackers,
                            allowing a company to prioritize the most critical
                            areas that need to be addressed the soonest.
                          </p>
                          <ul className="list p-3">
                            <li>
                              <span>
                                Network-based, which are used to identify
                                potential wired or wireless network security
                                attacks.
                              </span>
                            </li>
                            <li>
                              <span>
                                Host-based, which identifies vulnerabilities in
                                your servers and network hosts, allowing you to
                                see how the configurations and updates impact
                                security.
                              </span>
                            </li>
                            <li>
                              <span>
                                Database, which looks over your database
                                security against attacks.
                              </span>
                            </li>
                            <li>
                              <span>
                                Application, which looks at the vulnerabilities
                                in the network or web applications your company
                                is using.
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                      {activeTab === "tab3" && (
                        <div className="services-details-content">
                          <h3>Data Breach</h3>
                          <p className="p-3">
                            A data breach is a type of cybersecurity incident
                            that results in an unauthorized party gaining access
                            to your data or systems. It covers a broad range of
                            attacks, including information theft, ransomware,
                            phishing, malware, and a range of other common cyber
                            threats.
                          </p>
                          <p className="px-3">
                            Our data privacy specialists have the experience and
                            expertise with privacy policies, processes, and
                            technologies to build, run, assess, and optimize
                            your data privacy management. We deliver custom data
                            privacy solutions no matter your data environment or
                            risk profile.
                          </p>
                          <p className="p-3">
                            However, the ultimate outcome from a data breach is
                            a potential loss of data, damaged reputation, loss
                            of revenue, and a disruption to work. All of which
                            can be very costly to a business of any size.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab4" && (
                        <div className="services-details-content">
                          <h3>Data Privacy</h3>
                          <p className="p-3">
                            There’s a reason why privacy is such a high priority
                            for businesses today. In fact, there are multiple
                            reasons that make privacy issues one of the biggest
                            threats that a company might face.
                          </p>
                          <p className="px-3">
                            First off, a data breach that happens because the
                            company did not comply with regulations can result
                            in millions of dollars in fines. The CCPA in the
                            United States and the GDPR in the European Union aim
                            to protect user data from unauthorized access or
                            use. So not adhering to the sometimes strict rules
                            can result in harsh penalties for the business.
                          </p>
                          <p className="p-3">
                            But at the same time, compliance is essential not
                            just because of the fines your company would be
                            subject to. There’s also the aspect of maintaining
                            your company’s reputation, which can be even more
                            crucial for the long-term success of the business.
                            Data Privacy
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="services-details-content">
                  <h3>Your Business Security Starts Here</h3>
                  {/* <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                    cursus amet sem venenatis faucibus non maecenas ultricies
                    feugiat amet tellus condimentum eu sed sit ut tempus felis
                    sit sagittis morbi sed ultricies feugiat amet tellu indum eu
                    sed sit ut tempus felis sit sagitti morbi sed lorem
                    Ultricies feugiat amet tellus condimentum eu sed sit loremut
                    tempus felis sit sagittis morbi sed ultricies feugiat
                    ultricies feugiat amet tellus condimentum eu sed sit ut
                    tempus felis sit sagittis morbi sed ultricies feugiat amet
                    tellu indum eu sed sit.
                  </p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/identify.png"
                  alt=""
                />

                <p>
                  Identify and define risk within your industry and business
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/understand.png"
                  alt=""
                />
                <p>Understand and assess current security needs</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/ensure.png"
                  alt=""
                />
                <p>
                  Ensure enterprise resiliency through policies and procedures
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/defend.png"
                  alt=""
                />
                <p>Defend against malicious attack and unauthorized access</p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection title={"Talk to a Cybersecurity Expert"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cybersecurity;
