import React from "react";

const TechnologyPartnership = () => {
  // Array of image URLs
  const imageUrls = [
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/06/IBM-Platinum-Business-Partner-2020-2@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/06/Sentinell-green-blue@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/06/Red-Hat@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/06/Senzing_Logo_b-r-01@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/06/ms@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/06/DataRobot@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/05/collibra-img@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/05/mulesoft-img@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/05/dataricks-img@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/05/snowflake-img@2x.png.webp",
    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/05/boomi-img@2x.png.webp",

    "https://j5e2t5e4.stackpathcdn.com/us/wp-content/uploads/sites/2/2023/05/informatica-img@2x.png.webp",
  ];

  // Display a maximum of 10 images
  const imagesToDisplay = imageUrls.slice(0, 22);

  return (
    <div className="services-area pb-100 ">
      <div className="container">
        <div className="section-title pt-100">
          {/* <span>Keep Up With Our Latest Insights</span> */}
          <h2>Technology Partnerships</h2>
          <p className="pt-3">
            We pair our consulting, engineering, and service expertise with the
            world’s most acclaimed technology leaders
          </p>
        </div>

        <div className="row text-center">
          <div className="col-xxl-12 logoSlider">
            <div className="row justify-content-center logoCarousels">
              {imagesToDisplay.map((imageUrl, index) => (
                <div
                  key={index}
                  className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 mb-3 item"
                >
                  <img
                    width="368"
                    height="252"
                    src={imageUrl}
                    alt={`Image ${index}`}
                    className="img-fluid entered lazyloaded"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologyPartnership;
