import React, { useState } from "react";
import { Link } from "react-router-dom";

import LetUsHelpSection from "../../components/LetUsHelpSection";

function ManagedItServices() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Managed IT Services</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>Managed IT Services</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              {/* <div
                className="page-banner-image"
                // data-speed="0.08"
                // data-revert="true"
              >
                <img src="../assets/images/page-banner-image.png" alt="Tech Sevants" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/expertise-7.jpg"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>Tech Savants: One Team For All Your Managed IT Needs</h3>
                  <p>
                    With enterprise technology advancing at a relentless speed,
                    organizations must have the experience and knowledge to
                    maintain and optimize IT operations every single day. By
                    connecting with a dedicated team of IT-managed service
                    providers, you can benefit from comprehensive, secure, and
                    integrative support for your business IT infrastructure.
                  </p>
                  <p>
                    Tech Savants as your IT partner will help you understand and
                    leverage all facets of your IT infrastructure. We do this by
                    implementing client strategy and budgets to a proven IT
                    process based on standards and best practices.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>It starts with the right data strategy for you</span>
                  <p>Manage, analyze and uncover value with enterprise data.</p>
                  <p>
                    Tech Savants understands that your data is critical to
                    generating revenue, growing your business, and maintaining a
                    competitive advantage.
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-3">
                    {" "}
                    Strategic Advantage of Outsourcing IT Management to Tech
                    Savants Managed Services
                  </h3>
                  <p className="px-3 mb-4">
                    Many organizations fall under the misconception that IT
                    management is to be only delegated to the in-house IT team.
                    However, that’s far from the truth. An IT managed service
                    provider like us provides expert support and service for
                    your complete IT infrastructure, allowing you to focus on
                    what your business does best. The business benefits of Tech
                    Savants managed services make it a smart investment for
                    companies of any size.
                  </p>
                </div>

                <div className="services-details-content">
                  <h3>Benefits of Tech Savants Managed IT Services</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/monitoring.png"
                  alt=""
                />

                <p>Proactive monitoring & problem solving</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/data.png"
                  alt=""
                />
                <p>Data compliance support</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/down-time.png"
                  alt=""
                />
                <p>Minimize downtime</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/technology.png"
                  alt=""
                />
                <p>New Technology</p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection
                title={"Talk to a Managed IT Services Expert"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagedItServices;
