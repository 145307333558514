import React from "react";
import { Link } from "react-router-dom";

function HomeBlogArea() {
  return (
    <div>
      {" "}
      <div className="blog-area ptb-100" style={{ backgroundColor: "#F9F5F4" }}>
        <div className="container">
          <div className="section-title">
            <span>Read Our Latest Articles</span>
            <h2>Latest From Our Blog Post </h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-card">
                <div className="blog-image">
                  <img src="./assets/images/blog-img-2.webp" alt="image" />
                </div>
                <div className="blog-content with-padding">
                  <span>
                    By <Link to="/blogs">Admin</Link>
                  </span>
                  <h3>
                    <Link to="/blogs">
                      Key Takeaways from DGIQ East Conference 2024
                    </Link>
                  </h3>
                  <ul className="entry-meta">
                    <li>
                      <i className="ri-calendar-line"></i> December 16, 2023
                    </li>
                    {/* <li>
                      <i className="ri-price-tag-3-line"></i>{" "}
                      <Link to="/blogs">Business</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-card">
                <div className="blog-image">
                  <img src="./assets/images/blog-img-2.webp" alt="image" />
                </div>
                <div className="blog-content with-padding">
                  <span>
                    By <Link to="/blogs">Admin</Link>
                  </span>
                  <h3>
                    <Link to="/blogs">
                      Key Takeaways from DGIQ East Conference 2024
                    </Link>
                  </h3>
                  <ul className="entry-meta">
                    <li>
                      <i className="ri-calendar-line"></i> December 16, 2023
                    </li>
                    {/* <li>
                      <i className="ri-price-tag-3-line"></i>{" "}
                      <Link to="/blogs">Business</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-card">
                <div className="blog-image">
                  <img src="./assets/images/blog-img-2.webp" alt="image" />
                </div>
                <div className="blog-content with-padding">
                  <span>
                    By <Link to="/blogs">Admin</Link>
                  </span>
                  <h3>
                    <Link to="/blogs">
                      Key Takeaways from DGIQ East Conference 2024
                    </Link>
                  </h3>
                  <ul className="entry-meta">
                    <li>
                      <i className="ri-calendar-line"></i> December 16, 2023
                    </li>
                    {/* <li>
                      <i className="ri-price-tag-3-line"></i>{" "}
                      <Link to="/blogs">Business</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBlogArea;
