import React, { useState } from "react";
import { Link } from "react-router-dom";
import LetUsHelpSection from "../../components/LetUsHelpSection";

function TestAutomation() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>Test Automation</h2>

                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>Test Automation</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6"></div>
          </div>
        </div>
      </div>
      <div className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="services-details-desc">
                <div className="services-details-image">
                  <img
                    src="../assets/images/expertise-4.jpg"
                    alt="Tech Savants"
                    style={{ width: "100%", height: "70%" }}
                  />

                  <div className="icon">
                    <i className="ri-cast-line"></i>
                  </div>
                </div>
                <div className="services-details-content">
                  <h3>Test Automation</h3>
                  <p>
                    At Tech Savants, quality assurance and test automation
                    (QATA) is an integral part of any project, from the very
                    beginning. It assures quality, reduces testing expenditures,
                    and immediately addresses problems.
                  </p>
                  <p>
                    We’re always developing new testing accelerators and digital
                    products while applying AI/ML in innovative ways for optimal
                    performance.
                  </p>
                </div>
                <div className="services-details-quote">
                  <i className="flaticon-straight-quotes"></i>
                  <span>
                    Start Your Test Automation Early and Save Time Sooner
                  </span>

                  <p className="p-3">
                    Tech Savants knows that testing done correctly and
                    efficiently will improve your customer experience and help
                    you reach your business goals.
                  </p>
                </div>
                <div className="services-details-content">
                  <h3 className="py-5">Types of Test Automation</h3>
                  <div className="tab-container">
                    <div className="tab-buttons">
                      <button
                        className={`tab-btn ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab1")}
                      >
                        Functional Testing
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab2")}
                      >
                        Unit Testing
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab3")}
                      >
                        Performance Testing
                      </button>
                      <button
                        className={`tab-btn ${
                          activeTab === "tab4" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("tab4")}
                      >
                        Data-driven Testing
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "tab1" && (
                        <div className="services-details-content">
                          <h3>Functional Testing</h3>
                          <p className="p-3">
                            Functional testing assesses the software against the
                            set functional requirements/specifications. It
                            focuses on what the application does and mainly
                            involves black box testing.
                          </p>
                          <p className="px-3">
                            Black box testing is also known as behavioral
                            testing and involves testing functionality of
                            elements without delving into its inner workings.
                            This means that the tester is completely unaware of
                            the structure or design of the item being tested.
                          </p>
                          <p className="p-3">
                            Functional testing focuses primarily on testing the
                            main functions of the system, its basic usability,
                            its accessibility to users, and the like. Unit
                            testing, integration testing, smoke testing, and
                            user acceptance testing are all examples of
                            functional testing.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="services-details-content">
                          <h3>Unit Testing</h3>
                          <p className="p-3">
                            Unit testing involves running tests on individual
                            components or functions in isolation to verify that
                            they are working as required. It is typically done
                            in the development phase of the application and is
                            therefore often the first type of automated testing
                            done on an application.
                          </p>
                          <p className="px-3">
                            Unit tests are extremely beneficial because they
                            help identify bugs early in the development phase,
                            keeping the cost of fixing them as low as possible.
                            Unit tests are a good way of helping developers
                            understand the functionality of each unit in the
                            application and also facilitate the reusability of
                            code.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab3" && (
                        <div className="services-details-content">
                          <h3>Performance Testing</h3>
                          <p className="p-3">
                            Performance testing involves testing a software’s
                            speed, stability, and responsiveness under workload.
                          </p>
                          <p className="px-3">
                            The main purpose of performance testing is to
                            identify any possible performance hurdles and have
                            them removed to optimize the software’s ability to
                            deliver the best results to the end user.
                            Performance testing is an essential feature in
                            ensuring a software’s success in the market because
                            it helps identify potential issues users could face
                            such as slow operation of software under a heavy
                            workload.
                          </p>
                          <p className="p-3">
                            Additionally, critical software and medical programs
                            should be performance tested to ensure they can
                            perform reliably under the most stressful of
                            circumstances.
                          </p>
                        </div>
                      )}
                      {activeTab === "tab4" && (
                        <div className="services-details-content">
                          <h3>Data-driven Testing</h3>
                          <p className="p-3">
                            Data-driven testing involves the use of data
                            external to the system to extend your automated test
                            cases. Data is usually stored separately in a
                            spreadsheet format or a table and is fed into the
                            test cases.
                          </p>
                          <p className="px-3">
                            Data-driven testing is effective because often
                            testers have multiple sets of data when testing and
                            having to develop a new test for each set of data is
                            time-consuming and a wastage of resources.
                          </p>
                          <p className="p-3">
                            Data-driven testing allows for the same test to be
                            used for multiple sets of data, offering consistency
                            of results, and reduced investment of time and
                            resources. Data-driven tests facilitate reusability
                            of code, can be done in any phase of the software
                            development phase, and changes to the script do not
                            affect the test data.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="services-details-content">
                  <h3>Reach Your Business Goals With Accurate Testing</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-10 col-md-12 mx-auto row my-5">
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/identify.png"
                  alt=""
                />

                <p>
                  Identify and design types and levels of test automation to
                  ensure an optimal strategy
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/implement.png"
                  alt=""
                />
                <p>Implement intelligence when, where and how you need it</p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/test.png"
                  alt=""
                />
                <p>
                  Test systems to scale; make them responsive, secure and
                  resilient for optimal performance
                </p>
              </div>
              <div
                className="col-md-6 col-lg-3 cols text-center"
                id="expertPage-card"
              >
                <img
                  style={{ margin: "10px", width: "90px" }}
                  src="../assets/images/analyse.png"
                  alt=""
                />
                <p>
                  Analyze result patterns to predict potential future outcomes
                </p>
              </div>
            </div>
            <div className="col-lg-10 col-md-12 mx-auto">
              <LetUsHelpSection
                title={"Connect with a Test Automation Expert"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestAutomation;
