import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import BannerArea from "../components/BannerArea";
import AboutSection from "../components/AboutSection";
import LetUsHelpSection from "../components/LetUsHelpSection";
import OurServices from "../components/OurServices";
import WhyChooseUs from "../components/WhyChooseUs";
import ChooseUs from "../components/ChooseUs";
import TechnologyPartnership from "../components/Partners";
import HomeBlogArea from "../components/HomeBlogArea";

function Home() {
  return (
    <div>
      <BannerArea />
      <AboutSection />
      <OurServices />
      <ChooseUs />
      <TechnologyPartnership />
      <HomeBlogArea />
      <LetUsHelpSection />
    </div>
  );
}

export default Home;
