import React from "react";

function ChooseUs() {
  return (
    <div>
      {" "}
      <div className="working-process-area pt-100 pb-75">
        <div className="container">
          <div className="section-title">
            <span>Working Process</span>
            <h2>The streamlined steps we take to achieve results</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="single-working-process-card">
                <div className="number">01</div>
                <h3>Discovery</h3>
                <p>
                  Uncover client needs and objectives through comprehensive
                  analysis and discussions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-working-process-card">
                <div className="number">02</div>
                <h3>Planning</h3>
                <p>
                  Strategize and outline a meticulous roadmap tailored to
                  achieve specific goals.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-working-process-card">
                <div className="number">03</div>
                <h3>Execution</h3>
                <p>
                  Implement and refine methodologies through rigorous testing
                  and adaptation.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-working-process-card">
                <div className="number">04</div>
                <h3>Delivery</h3>
                <p>
                  Ensure seamless implementation and surpass expectations with
                  exceptional outcomes.
                </p>
              </div>
            </div>

            <div className="working-process-line"></div>
          </div>
        </div>
      </div>
      <div className="why-choose-us-area-with-video ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-12">
              {/* <div
                className="why-choose-us-video-view"
                // data-speed="0.09"
                // data-revert="true"
              >
                <a
                  href="https://www.youtube.com/watch?v=ODfy2YIKS1M"
                  className="video-btn popup-youtube"
                >
                  <i className="flaticon-play-button"></i>
                </a>
              </div> */}
            </div>

            <div className="col-lg-6 col-md-12">
              <div
                className="why-choose-us-content wrap-color"
                // data-aos="fade-left"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <span>Why Choose Us</span>
                <h3>Our Working Process To Help Your Boost Your Business</h3>
                <p>
                  Tech Savants is a digital engineering and consulting firm
                  helping clients navigate and accelerate their digital
                  transformation journeys.
                </p>

                <div className="choose-us-inner-box">
                  <div className="icon">
                    <img
                      className="icon-img"
                      src="./assets/images/public-relation.png"
                    />
                  </div>
                  <h4>Engagement When and Where You Need It</h4>
                  <p>
                    We offer everything you need from concept to implementation
                    to ongoing management, at any point in your digital journey.
                  </p>
                </div>

                <div className="choose-us-inner-box">
                  <div className="icon">
                    <img
                      className="icon-img"
                      src="./assets/images/engineering.png"
                    />
                  </div>
                  <h4>Automation Moves Your Vision To Value Faster</h4>
                  <p>
                    While our competitors throw bodies at a problem, we use
                    automation to deliver value to you quicker.
                  </p>
                </div>

                <div className="choose-us-inner-box">
                  <div className="icon">
                    <img
                      className="icon-img"
                      src="./assets/images/on-time.png"
                    />
                  </div>
                  <h4>Our Expertise Means On Time and On Budget</h4>
                  <p>
                    Our experience means we’ve seen it all before and we know
                    how to approach your issue right the first time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUs;
