import React from "react";
import PricingArea from "../components/PricingArea";
import FunFactArea from "../components/FunFactArea";
import FaqSection from "../components/FaqSection";
import TeamArea from "../components/TeamArea";

function About() {
  return (
    <div>
      <div className="page-banner-area">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-content"
                // data-aos="fade-right"
                // data-aos-delay="50"
                // data-aos-duration="500"
                // data-aos-once="true"
              >
                <h2>About</h2>

                {/* <ul>
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>About</li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div
                className="page-banner-image"
                data-speed="0.08"
                data-revert="true"
              >
                <img
                  src="./assets/images/about.png"
                  alt="Tech Savants"
                  style={{ height: "180px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-image-wrap">
                <img
                  src="./assets/images/about-2.jpg"
                  alt="Tech Savants"
                  // data-aos="fade-down"
                  // data-aos-delay="50"
                  // data-aos-duration="500"
                  // data-aos-once="true"
                />
                <img
                  src="./assets/images/about-3.jpg"
                  alt="Tech Savants"
                  // data-aos="fade-up"
                  // data-aos-delay="50"
                  // data-aos-duration="500"
                  // data-aos-once="true"
                />

                {/* <div className="about-shape-3" data-speed="0.08" data-revert="true">
                  <img src="./assets/images/about/shape-1.png" alt="Tech Savants" />
                </div> */}
              </div>
            </div>

            <div
              className="col-lg-6 col-md-12"
              // data-aos="fade-left"
              // data-aos-delay="50"
              // data-aos-duration="500"
              // data-aos-once="true"
            >
              <div className="about-content">
                <span>About Our Company</span>
                <h3>Tech-driven Solutions, Business-focused Results.</h3>
                <p>
                  Tech Savants has 2 decades of experience in assisting
                  organizations throughout the world with their IT & Digital
                  needs and support. We specialize in helping our clients to
                  connect with their customers, build ideas, and sell their
                  services through a variety of services driven with proven ROI.
                  Our philosophy is to connect perfectly with our clients in
                  order to provide effective, efficient, and collaborative
                  solutions for your business's success. "Our mission is your
                  success." We value our clients’ success ahead of our own. We
                  measure our own success on how well we help them achieve their
                  business goals and objectives using our expert & professional
                  services.
                </p>
                {/* <div className="about-btn">
                  <a href="about-one.html" className="default-btn">
                    Learn More
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FunFactArea />
      {/* <TeamArea /> */}
      {/* <PricingArea /> */}
      <FaqSection />
    </div>
  );
}

export default About;
