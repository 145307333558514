import React, { useEffect, useState } from "react";
import "../styles/navbar.css";
import { Link, useLocation } from "react-router-dom";

function NavBar() {
  const [isSticky, setIsSticky] = useState(false);
  const [openMenu, setopenMenu] = useState(false);
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  const handleRemoveElement = () => {
    setIsVisible(!isVisible);
  };
  // Get the current pathname from the location object
  const currentPath = location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div
        // className="navbar-area "
        className={isSticky ? "navbar-area is-sticky" : "navbar-area"}
      >
        <div className="main-responsive-nav">
          <div className="container">
            <div
              className={
                openMenu
                  ? "main-responsive-menu "
                  : "main-responsive-menu mean-container"
              }
            >
              <div className="mean-bar">
                {!isVisible ? (
                  <img
                    src="../assets/images/menu.svg"
                    className="meanmenu-reveal "
                    onClick={handleRemoveElement}
                    style={{
                      height: "25px",
                      position: "absolute",
                      right: "0",
                      marginTop: "16px",
                    }}
                  />
                ) : (
                  <img
                    src="../assets/images/close.svg"
                    className="meanmenu-reveal "
                    onClick={handleRemoveElement}
                    style={{
                      height: "25px",
                      position: "absolute",
                      right: "0",
                      marginTop: "16px",
                    }}
                  />
                )}

                {isVisible && (
                  <nav className="mean-nav">
                    <ul className="navbar-nav me-auto">
                      <li className="nav-item" onClick={handleRemoveElement}>
                        <Link
                          to={"/"}
                          className={
                            currentPath === "/" ? "nav-link active" : "nav-link"
                          }
                        >
                          Home
                        </Link>
                      </li>

                      <li className="nav-item" onClick={handleRemoveElement}>
                        <Link
                          to={"/about"}
                          className={
                            currentPath === "/about"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          About
                        </Link>
                      </li>

                      <li className="nav-item" onClick={handleRemoveElement}>
                        <Link
                          to={"/expertise"}
                          className={
                            currentPath === "/expertise"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Expertise
                          <i className="ri-arrow-down-s-line"></i>
                        </Link>

                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <Link to="/expertise/data-ai" className="nav-link">
                              Data & AI
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/expertise/integrations-applications"
                              className="nav-link"
                            >
                              Integrations & applications
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/expertise/devxops" className="nav-link">
                              Dev XOps
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="expertise/test-automation"
                              className="nav-link"
                            >
                              Test Automation
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/expertise/cybersecurity"
                              className="nav-link"
                            >
                              Cybersecurity
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/expertise/business-automations"
                              className="nav-link"
                            >
                              Business Automation
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="expertise/managed-it-services"
                              className="nav-link"
                            >
                              Managed IT Services
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/expertise/generative-ai"
                              className="nav-link"
                            >
                              Generative AI
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item" onClick={handleRemoveElement}>
                        <Link
                          to={"/blogs"}
                          className={
                            currentPath === "/blogs"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Blog
                        </Link>
                      </li>

                      <li className="nav-item" onClick={handleRemoveElement}>
                        <Link
                          to="/contact"
                          className={
                            currentPath === "/contact"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>

                    <div className="others-options d-flex align-items-center">
                      <div className="option-item">
                        <a href="contact.html" className="default-btn">
                          Contact Now
                        </a>
                      </div>
                    </div>
                  </nav>
                )}
              </div>
              <div className="logo" style={{ height: "70px" }}>
                <Link href="index">
                  <img
                    src="./assets/images/logo.svg"
                    className="black-logo"
                    alt="image"
                    style={{ height: "60px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="main-navbar">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="navbar-brand" to="/">
                <img
                  src="./assets/images/logo.svg"
                  className="black-logo"
                  style={{ height: "60px" }}
                  alt="image"
                />
              </Link>

              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto">
                  <li className="nav-item" onClick={handleRemoveElement}>
                    <Link
                      to={"/"}
                      className={
                        currentPath === "/" ? "nav-link active" : "nav-link"
                      }
                    >
                      Home
                    </Link>
                  </li>

                  <li className="nav-item" onClick={handleRemoveElement}>
                    <Link
                      to={"/about"}
                      className={
                        currentPath === "/about"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      About
                    </Link>
                  </li>

                  <li className="nav-item" onClick={handleRemoveElement}>
                    <Link
                      to={"/expertise"}
                      className={
                        currentPath === "/expertise"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Expertise
                      <i className="ri-arrow-down-s-line"></i>
                    </Link>

                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link to="/expertise/data-ai" className="nav-link">
                          Data & AI
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/expertise/integrations-applications"
                          className="nav-link"
                        >
                          Integrations & applications
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/expertise/devxops" className="nav-link">
                          Dev XOps
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="expertise/test-automation"
                          className="nav-link"
                        >
                          Test Automation
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/expertise/cybersecurity"
                          className="nav-link"
                        >
                          Cybersecurity
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/expertise/business-automations"
                          className="nav-link"
                        >
                          Business Automation
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="expertise/managed-it-services"
                          className="nav-link"
                        >
                          Managed IT Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/expertise/generative-ai"
                          className="nav-link"
                        >
                          Generative AI
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item" onClick={handleRemoveElement}>
                    <Link
                      to={"/blogs"}
                      className={
                        currentPath === "/blogs"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Blog
                    </Link>
                  </li>

                  <li className="nav-item" onClick={handleRemoveElement}>
                    <Link
                      to="/contact"
                      className={
                        currentPath === "/contact"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Contact
                    </Link>
                  </li>
                </ul>

                <div className="others-options d-flex align-items-center">
                  <div className="option-item">
                    <Link to="/contact" className="default-btn">
                      Contact Now
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="others-option-for-responsive">
          <div className="container">
            {/* <div className="dot-menu">
              <div className="inner">
                <div className="circle circle-one"></div>
                <div className="circle circle-two"></div>
                <div className="circle circle-three"></div>
              </div>
            </div> */}

            <div className="container">
              <div className="option-inner">
                <div className="others-options d-flex align-items-center">
                  <div className="option-item">
                    <form className="search-box">
                      <input
                        type="text"
                        className="input-search"
                        placeholder="Search..."
                      />
                      <button type="submit">
                        <i className="ri-search-line"></i>
                      </button>
                    </form>
                  </div>

                  <div className="option-item">
                    <div className="dropdown language-option">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="ri-earth-line"></i>
                        <span className="lang-name"></span>
                      </button>
                      <div className="dropdown-menu language-dropdown-menu">
                        <Link className="dropdown-item" href="#">
                          <img src="assets/images/uk.png" alt="flag" />
                          English
                        </Link>
                        <Link className="dropdown-item" href="#">
                          <img src="assets/images/china.png" alt="flag" />
                          简体中文
                        </Link>
                        <Link className="dropdown-item" href="#">
                          <img src="assets/images/uae.png" alt="flag" />
                          العربيّة
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="option-item">
                    <Link href="/contact" className="default-btn">
                      Contact Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
